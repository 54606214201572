import * as React from "react"
import { SEO } from "../components/seo"
import Layout from '../components/layout'
import "../css/page-swipers.scss"
import { StaticImage } from "gatsby-plugin-image"
import { useRef, useState } from "react"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import "swiper/css/pagination"
import "swiper/css/navigation"
import { Pagination, Navigation } from "swiper"

// Images
import slideImage1 from '../images/placeholder.jpg';

const SwiperPage = () => {
  return (
	<Layout>
		<main>
			<section id="hero">
				<h1>Swiper Examples</h1>
			</section>
			<section id="intro">
            <Swiper
                spaceBetween={30}
                hashNavigation={{
                    watchState: true,
                }}
                pagination={{
                    clickable: true,
                }}
                loop={true}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide data-hash="slide1">
                    <StaticImage
                        src="../images/placeholder.jpg"
                        alt="Slide 1 Background Image"
                    />
                    <div className="slideContent">Slide 1</div>
                </SwiperSlide>
                <SwiperSlide data-hash="slide2">Slide 2</SwiperSlide>
                <SwiperSlide data-hash="slide3">Slide 3</SwiperSlide>
                <SwiperSlide data-hash="slide4">Slide 4</SwiperSlide>
                <SwiperSlide data-hash="slide5">Slide 5</SwiperSlide>
                <SwiperSlide data-hash="slide6">Slide 6</SwiperSlide>
                <SwiperSlide data-hash="slide7">Slide 7</SwiperSlide>
                <SwiperSlide data-hash="slide8">Slide 8</SwiperSlide>
                <SwiperSlide data-hash="slide9">Slide 9</SwiperSlide>
            </Swiper>
			</section>
		</main>
	</Layout>
  )
}
export default SwiperPage

export const Head = () => (
	<SEO
        title="Swiper Page"
        description="This page has swiper examples for the sitekeeper template."
    />
)
